<div class="error404">
  <div class="container">
    <svg
      width="211"
      height="211"
      viewBox="0 0 211 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_605_384)">
        <path
          d="M189.56 165.255C200.038 149.358 206.142 130.323 206.142 109.859C206.142 54.1839 161.009 9.05042 105.333 9.05042C49.6596 9.05042 4.52612 54.1839 4.52612 109.859C4.52612 130.323 10.6305 149.358 21.1066 165.255H189.56Z"
          fill="#4E2AA5" />
        <path
          d="M189.832 100.767C188.692 101.079 187.493 101.249 186.256 101.249C178.806 101.249 172.767 95.2125 172.767 87.7628C172.767 95.2125 166.731 101.249 159.281 101.249C151.833 101.249 145.794 95.2125 145.794 87.7628C145.794 95.2125 139.756 101.249 132.308 101.249C124.858 101.249 118.821 95.2125 118.821 87.7628C118.821 95.2125 112.783 101.249 105.335 101.249C97.8852 101.249 91.8467 95.2125 91.8467 87.7628C91.8467 95.2125 85.8099 101.249 78.3602 101.249C70.9122 101.249 64.8737 95.2125 64.8737 87.7628C64.8737 95.2125 58.8352 101.249 51.3872 101.249C43.9376 101.249 37.9007 95.2125 37.9007 87.7628C37.9007 95.2125 31.8622 101.249 24.4142 101.249C23.175 101.249 21.976 101.079 20.8364 100.767V164.844C26.2609 173.163 32.8812 180.632 40.453 187.006H170.215C177.789 180.632 184.407 173.165 189.833 164.844V100.767H189.832Z"
          fill="#E6FCFF" />
        <path
          d="M51.3856 101.251C43.9376 101.251 37.8991 95.2124 37.8991 87.7644C37.8991 95.2124 31.8606 101.251 24.4126 101.251C23.1734 101.251 21.9744 101.081 20.8364 100.767V164.844C26.2609 173.165 32.8812 180.632 40.453 187.006H51.3872V101.251H51.3856Z"
          fill="#CCEBFF" />
        <path
          d="M131.705 187.439H78.9631V126.74H131.705V187.439Z"
          fill="#4070EA" />
        <path
          d="M95.2109 187.439H78.9631V126.74H95.2109V187.439Z"
          fill="#205DD9" />
        <path
          d="M85.4514 134.095H125.215V180.084H85.4514V134.095Z"
          fill="#5F83FA" />
        <path
          d="M85.4514 134.095H95.2107V180.084H85.4514V134.095Z"
          fill="#4070EA" />
        <path
          d="M128.46 160.406H82.2082C80.4145 160.406 78.9631 158.953 78.9631 157.161C78.9631 155.369 80.4145 153.916 82.2082 153.916H128.46C130.252 153.916 131.705 155.369 131.705 157.161C131.705 158.953 130.252 160.406 128.46 160.406Z"
          fill="#4070EA" />
        <path
          d="M82.2082 160.406H95.2093V153.916H82.2082C80.4161 153.916 78.9631 155.369 78.9631 157.161C78.9631 158.953 80.4161 160.406 82.2082 160.406Z"
          fill="#205DD9" />
        <path
          d="M95.2109 163.507C93.4172 163.507 91.9658 162.054 91.9658 160.262V153.918C91.9658 152.126 93.4172 150.673 95.2109 150.673C97.003 150.673 98.4543 152.126 98.4543 153.918V160.262C98.4543 162.054 97.003 163.507 95.2109 163.507Z"
          fill="#8CECF8" />
        <path
          d="M63.0801 114.286H39.6929C38.7028 114.286 37.8992 115.089 37.8992 116.078V159.427C37.9088 159.427 37.9168 159.426 37.9265 159.426H64.8465C64.8545 159.426 64.8642 159.427 64.8738 159.427V116.078C64.8722 115.088 64.0702 114.286 63.0801 114.286Z"
          fill="#8CECF8" />
        <path
          d="M51.3857 116.078C51.3857 115.089 52.1893 114.286 53.1794 114.286H39.6929C38.7028 114.286 37.8992 115.089 37.8992 116.078V159.427C37.9088 159.427 37.9168 159.426 37.9265 159.426H51.3873V116.078H51.3857Z"
          fill="#51E0F4" />
        <path
          d="M64.8464 159.426C64.8546 159.426 64.8644 159.427 64.8742 159.427V159.426H64.8464Z"
          fill="#51E0F4" />
        <path
          d="M170.726 186.574H39.9404C45.3376 191.177 51.2218 195.224 57.511 198.62H153.157C159.447 195.224 165.331 191.177 170.726 186.574Z"
          fill="#8CECF8" />
        <path
          d="M94.8669 186.574H39.9404C45.3376 191.177 51.2218 195.224 57.511 198.62H94.9875C93.9765 198.62 92.9768 198.371 92.0928 197.878C86.3549 194.662 88.8349 186.574 94.8669 186.574Z"
          fill="#51E0F4" />
        <path
          d="M108.088 210.628C124.662 210.183 140.236 205.736 153.885 198.22H56.7827C70.43 205.736 86.0043 210.183 102.578 210.628C102.696 210.631 102.815 210.634 102.932 210.636C103.731 210.655 104.531 210.667 105.333 210.667C106.135 210.667 106.936 210.655 107.734 210.636C107.853 210.634 107.971 210.631 108.088 210.628Z"
          fill="#A5C013" />
        <path
          d="M92.6825 198.22H56.7827C67.5063 204.125 79.421 208.135 92.0749 209.799C86.4206 207.987 86.9124 199.437 92.6825 198.22Z"
          fill="#8DAE16" />
        <path
          d="M68.4274 17.5642H43.6932L38.7654 77.1212H65.7384L68.4274 17.5642Z"
          fill="#F4B500" />
        <path
          d="M67.1947 44.8603L68.4275 17.5642H43.6934L41.4336 44.8603H67.1947Z"
          fill="#EE9D00" />
        <path
          d="M38.7654 77.1211V89.9261C38.7654 97.3758 44.8022 103.414 52.2519 103.414C59.6999 103.414 65.7384 97.3758 65.7384 89.9261V77.1211H38.7654Z"
          fill="#FFE600" />
        <path
          d="M65.3062 77.1211V89.9261C65.3062 97.3758 71.3446 103.414 78.7927 103.414C86.2407 103.414 92.2792 97.3758 92.2792 89.9261V77.1211H65.3062Z"
          fill="#FE4675" />
        <path
          d="M93.6228 17.5642H67.9935L65.3062 77.1212H92.2792L93.6228 17.5642Z"
          fill="#EC3969" />
        <path
          d="M93.0074 44.8603L93.623 17.5642H67.9936L66.7625 44.8603H93.0074Z"
          fill="#DA2C5D" />
        <path
          d="M91.8469 77.1211V89.9261C91.8469 97.3758 97.8854 103.414 105.333 103.414C112.783 103.414 118.82 97.3758 118.82 89.9261V77.1211H91.8469Z"
          fill="#FFE600" />
        <path
          d="M117.476 17.5642H93.1906L91.8469 77.1212H118.82L117.476 17.5642Z"
          fill="#F4B500" />
        <path
          d="M118.093 44.8603L117.476 17.5642H93.1903L92.5747 44.8603H118.093Z"
          fill="#EE9D00" />
        <path
          d="M198.443 77.1211V89.9261C198.443 97.3758 192.405 103.414 184.957 103.414C177.509 103.414 171.47 97.3758 171.47 89.9261V77.1211H198.443Z"
          fill="#FE4675" />
        <path
          d="M18.3082 23.802L12.2231 77.1212H39.1978L44.1256 17.5642H25.213C21.6529 17.5642 18.6699 20.2596 18.3082 23.802Z"
          fill="#EC3969" />
        <path
          d="M28.778 44.8603H41.8675L44.1257 17.5642H25.2131C21.653 17.5642 18.6699 20.2596 18.3083 23.802L14.7161 55.2866C16.558 49.269 22.177 44.8603 28.778 44.8603Z"
          fill="#DA2C5D" />
        <path
          d="M14.0747 60.8959L12.2231 77.1212H14.0747V60.8959Z"
          fill="#DA2C5D" />
        <path
          d="M192.359 23.802C191.998 20.258 189.015 17.5642 185.454 17.5642H166.543L171.471 77.1212H198.445L192.359 23.802Z"
          fill="#EC3969" />
        <path
          d="M181.889 44.8603C188.49 44.8603 194.109 49.269 195.952 55.285L192.359 23.802C191.998 20.2596 189.015 17.5642 185.454 17.5642H166.543L168.801 44.8603H181.889Z"
          fill="#DA2C5D" />
        <path
          d="M171.903 77.1211V89.9261C171.903 97.3758 165.864 103.414 158.416 103.414C150.967 103.414 144.93 97.3758 144.93 89.9261V77.1211H171.903Z"
          fill="#FFE600" />
        <path
          d="M142.241 17.5642H166.975L171.903 77.1212H144.93L142.241 17.5642Z"
          fill="#F4B500" />
        <path
          d="M169.233 44.8603L166.975 17.5642H142.241L143.474 44.8603H169.233Z"
          fill="#EE9D00" />
        <path
          d="M145.362 77.1211V89.9261C145.362 97.3758 139.324 103.414 131.876 103.414C124.426 103.414 118.387 97.3758 118.387 89.9261V77.1211H145.362Z"
          fill="#FE4675" />
        <path
          d="M117.044 17.5642H142.673L145.362 77.1212H118.388L117.044 17.5642Z"
          fill="#EC3969" />
        <path
          d="M143.906 44.8603L142.673 17.5642H117.044L117.66 44.8603H143.906Z"
          fill="#DA2C5D" />
        <path
          d="M164.038 0H46.6282C44.5291 0 42.8286 1.70209 42.8286 3.80117V30.3692H167.84V3.80117C167.84 1.70209 166.138 0 164.038 0Z"
          fill="#E6FCFF" />
        <path
          d="M82.594 0H46.6283C44.5292 0 42.8271 1.70209 42.8271 3.80117V30.3692H78.7928V3.80117C78.7928 1.70209 80.4949 0 82.594 0Z"
          fill="#CCEBFF" />
        <path
          d="M124.556 18.4288H86.1122C84.3202 18.4288 82.8672 16.9759 82.8672 15.1838C82.8672 13.3933 84.3202 11.9403 86.1122 11.9403H124.556C126.348 11.9403 127.801 13.3933 127.801 15.1838C127.801 16.9759 126.348 18.4288 124.556 18.4288Z"
          fill="#CCEBFF" />
        <path
          d="M70.6084 18.4288H59.1374C57.3453 18.4288 55.8923 16.9759 55.8923 15.1838C55.8923 13.3933 57.3453 11.9403 59.1374 11.9403H70.6084C72.4005 11.9403 73.8535 13.3933 73.8535 15.1838C73.8535 16.9759 72.4005 18.4288 70.6084 18.4288Z"
          fill="#B8DFF4" />
        <path
          d="M151.529 18.4288H140.06C138.266 18.4288 136.815 16.9759 136.815 15.1838C136.815 13.3933 138.266 11.9403 140.06 11.9403H151.529C153.323 11.9403 154.774 13.3933 154.774 15.1838C154.774 16.9759 153.321 18.4288 151.529 18.4288Z"
          fill="#CCEBFF" />
        <path
          d="M168.225 29.3887C141.792 29.3887 70.5892 29.3887 41.1442 29.3887C39.3521 29.3887 37.8992 30.8416 37.8992 32.6337C37.8992 34.4258 39.3521 35.8788 41.1442 35.8788H168.225C170.017 35.8788 171.47 34.4258 171.47 32.6337C171.47 30.8416 170.017 29.3887 168.225 29.3887Z"
          fill="#D9F3FF" />
        <path
          d="M75.456 32.6337C75.456 30.8416 76.9074 29.3887 78.6994 29.3887C63.8403 29.3887 50.4197 29.3887 41.1442 29.3887C39.3521 29.3887 37.8992 30.8416 37.8992 32.6337C37.8992 34.4258 39.3521 35.8788 41.1442 35.8788H78.6994C76.9074 35.8788 75.456 34.4258 75.456 32.6337Z"
          fill="#B8DFF4" />
        <path
          d="M63.0801 114.286H39.6929C38.7028 114.286 37.8992 115.089 37.8992 116.078V125.967H64.8738V116.078C64.8722 115.088 64.0702 114.286 63.0801 114.286Z"
          fill="#51E0F4" />
        <path
          d="M53.1794 114.286H39.6929C38.7028 114.286 37.8992 115.089 37.8992 116.078V125.967H51.3857V116.078C51.3857 115.088 52.1893 114.286 53.1794 114.286Z"
          fill="#15D4F0" />
        <path
          d="M70.6872 164.4C70.6872 161.174 68.0722 158.559 64.8465 158.559H37.9265C34.7007 158.559 32.0857 161.174 32.0857 164.4C32.0857 167.626 34.7007 170.241 37.9265 170.241H64.8465C68.0722 170.241 70.6872 167.626 70.6872 164.4Z"
          fill="#5F83FA" />
        <path
          d="M46.4385 164.4C46.4385 161.174 49.0535 158.559 52.2793 158.559H37.9265C34.7007 158.559 32.0857 161.174 32.0857 164.4C32.0857 167.626 34.7007 170.241 37.9265 170.241H52.2793C49.0535 170.241 46.4385 167.626 46.4385 164.4Z"
          fill="#4070EA" />
        <path
          d="M129.661 114.286H81.0076C79.8777 114.286 78.9631 115.202 78.9631 116.33V127.605H131.705V116.33C131.705 115.2 130.789 114.286 129.661 114.286Z"
          fill="#51E0F4" />
        <path
          d="M97.2537 114.286H81.0076C79.8777 114.286 78.9631 115.202 78.9631 116.33V127.605H95.2093V116.33C95.2109 115.2 96.1255 114.286 97.2537 114.286Z"
          fill="#15D4F0" />
        <path
          d="M12.2231 77.1211V89.9261C12.2231 97.3758 18.2616 103.414 25.7097 103.414C33.1593 103.414 39.1978 97.3758 39.1978 89.9261V77.1211H12.2231Z"
          fill="#FE4675" />
        <path
          d="M170.975 114.286H147.587C146.596 114.286 145.794 115.089 145.794 116.078V159.427C145.802 159.427 145.812 159.426 145.822 159.426H172.74C172.75 159.426 172.758 159.427 172.767 159.427V116.078C172.767 115.088 171.965 114.286 170.975 114.286Z"
          fill="#8CECF8" />
        <path
          d="M159.281 116.078C159.281 115.089 160.085 114.286 161.075 114.286H147.587C146.596 114.286 145.794 115.089 145.794 116.078V159.427C145.802 159.427 145.812 159.426 145.822 159.426H159.281V116.078Z"
          fill="#51E0F4" />
        <path
          d="M172.742 159.426C172.75 159.426 172.76 159.427 172.769 159.427V159.426H172.742Z"
          fill="#51E0F4" />
        <path
          d="M170.975 114.286H147.587C146.596 114.286 145.794 115.089 145.794 116.078V125.967H172.767V116.078C172.767 115.088 171.965 114.286 170.975 114.286Z"
          fill="#51E0F4" />
        <path
          d="M161.075 114.286H147.587C146.596 114.286 145.794 115.089 145.794 116.078V125.967H159.281V116.078C159.281 115.088 160.085 114.286 161.075 114.286Z"
          fill="#15D4F0" />
        <path
          d="M178.581 164.4C178.581 161.174 175.966 158.559 172.742 158.559H145.822C142.596 158.559 139.981 161.174 139.981 164.4C139.981 167.626 142.596 170.241 145.822 170.241H172.74C175.966 170.241 178.581 167.626 178.581 164.4Z"
          fill="#5F83FA" />
        <path
          d="M154.334 164.4C154.334 161.174 156.947 158.559 160.173 158.559H145.822C142.596 158.559 139.981 161.174 139.981 164.4C139.981 167.626 142.596 170.241 145.822 170.241H160.173C156.947 170.241 154.334 167.626 154.334 164.4Z"
          fill="#4070EA" />
      </g>
      <defs>
        <clipPath id="clip0_605_384">
          <rect width="210.667" height="210.667" fill="white" />
        </clipPath>
      </defs>
    </svg>

    <h1>{{ 'OOPS' | translate }}</h1>
    <p>{{ 'STORE_NOT_FOUND' | translate }}</p>
  </div>
</div>
