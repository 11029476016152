import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { ViewService } from '@data-access/view/view.service'
import { CartCount, CartDelete, CartList } from '@models/cart.model'
import { qrStorageId } from '@models/common'
import { Response } from '@models/response'
import { Observable, tap } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CartService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string,
    private _viewService: ViewService
  ) {}

  getCarts(storeSlug: string, qr: string): Observable<Response<CartList[]>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('deviceId', this._viewService.getDeviceId(qr))
      .set('serviceType', this._viewService.getServiceType(qr))
      .set('customerDeviceId', this._viewService.getCustomerDeviceId(qr) )

    return this.http.get<Response<CartList[]>>(
      `/api/idefood/${storeSlug}/cart`,
      {
        headers: headers,
        params: params
      }
    )
  }

  addCart(
    storeSlug: string,
    payload: CartList
  ): Observable<Response<CartList>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.post<Response<CartList>>(
      `/api/idefood/${storeSlug}/cart`,
      payload,
      {
        headers: headers
      }
    ).pipe(
      tap(() => {
        this._viewService.setIsCartUpdated('true', payload.qrHash)
      })
    )
  }

  updateCart(
    storeSlug: string,
    id: number,
    payload: CartList,
    qrHash: string
  ): Observable<Response<CartList>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.put<Response<CartList>>(
      `/api/idefood/${storeSlug}/cart/${id}`,
      payload,
      {
        headers: headers
      }
    ).pipe(
      tap(() => {
        this._viewService.setIsCartUpdated('true', qrHash)
      })
    )
  }

  deleteCart(
    storeSlug: string,
    id: number,
    payload: CartDelete,
    qrHash: string
  ): Observable<Response<CartDelete>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.delete<Response<CartDelete>>(
      `/api/idefood/${storeSlug}/cart/${id}`,
      {
        headers: headers,
        body: payload
      }
    ).pipe(
      tap(() => {
        this._viewService.setIsCartUpdated('true', qrHash)
      })
    )
  }

  checkCart(
    storeSlug: string,
    payload: CartDelete
  ): Observable<Response<CartDelete>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')

    return this.http.delete<Response<CartDelete>>(
      `/api/idefood/${storeSlug}/cart`,
      {
        headers: headers,
        body: payload
      }
    )
  }
  

  getCartCount(storeSlug: string, qr: string = ''): Observable<Response<CartCount>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('deviceId', this._viewService.getDeviceId(qr))
      .set('serviceType', this._viewService.getServiceType(qr))
      .set('customerDeviceId', this._viewService.getCustomerDeviceId(qr))

    return this.http.get<Response<CartCount>>(
      `/api/idefood/${storeSlug}/cart/count`,
      {
        headers: headers,
        params: params
      }
    )
  }

  updateCartsBulk(
    qr: string,
    storeSlug: string
  ): Observable<Response<CartList[]>> {
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      const data: qrStorageId = {
        "deviceId": this._viewService.getDeviceId(qr),
        "serviceType": this._viewService.getServiceType(qr),
        "customerDeviceId": this._viewService.getCustomerDeviceId(qr),
      }

      return this.http.patch<Response<CartList[]>>(
        `/api/idefood/${storeSlug}/cart`,
        data,
        {
          headers: headers
        }
      );
  }

}
