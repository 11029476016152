import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ToastService } from '../data-access/toast/toast.service'
import { HttpError } from '../models/common'
import { ViewService } from '@data-access/view/view.service'
import { CookieStorageKey, ErrorMessage } from '@utils/const'
import { CartUtilitiesService } from '@data-access/utilities/cart-utilities.service'
import { DataStorageService } from '../data-storage/data-storage.service'
import { StoreInfo } from '@models/home.model'
import { AuthMemberService } from '@auth/data-access/auth-member.service'
import { SsrCookieService } from 'ngx-cookie-service-ssr'

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  storeInfo: StoreInfo = new Object() as StoreInfo
  constructor(
    private toastService: ToastService,
    private router: Router,
    private _viewService: ViewService,
    private _cartUtilitiesService: CartUtilitiesService,
    private _dataStorageService: DataStorageService,
    private _authMemberService: AuthMemberService,
    private _ssrCookieService: SsrCookieService
  ) {
    const dataStoreInfo = this._dataStorageService.getData('storeInfoResponse');
    this.storeInfo = dataStoreInfo
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(
        {
          next: (res: any) => {
            if (res.hasOwnProperty('body') && res?.body.hasOwnProperty('code')) {
              if (res?.body.code === 'FORBIDDEN'  && this._ssrCookieService.check(CookieStorageKey.member)) {
                this._authMemberService.logout().subscribe(
                  {
                    next: async () : Promise<void> => {
                      this._viewService.logout('login')
                      await this._cartUtilitiesService.updateCarts()
                    }
                  }
                )
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            switch (error?.status) {
              case HttpError.BadRequest:
                if (error.error.error === ErrorMessage.MERCHANT_TABLE_QR_OPEN_BILL_PAID) {
                  console.log(error.error.error)
                  return
                }
                break
              case HttpError.NotFound:
                if (error.error.error === ErrorMessage.MERCHANT_NOT_FOUND) {
                  this.router.navigate(['/e/404'])
                  this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
                } else if (
                  error.error.error === ErrorMessage.MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE
                ) {
                  this.router.navigate([
                    '/e/table-management-requires-activation'
                  ])
                }
                break
  
              case HttpError.UnprocessableEntityError:
                if (error.error.error === ErrorMessage.ORDER_CART_EMPTY) {
                  return
                }
  
                if (error.error.hasOwnProperty('data') && Object.keys(error.error.data).length > 0) {
                  if (error.error?.data['error-message'] === ErrorMessage.ORDER_HAS_BEEN_ALREADY_VOIDED) {
                    this.router.navigate(['/e/please-scan-qr/order-has-been-voided'])
                  } else if (error.error?.data['error-message'] === ErrorMessage.ORDER_HAS_BEEN_PAID) {
                    this.router.navigate(['/e/please-scan-qr/order-has-been-paid'])
                  }
                } 
                
                if (!error.error.hasOwnProperty('data') && error.error.error === ErrorMessage.MERCHANT_TABLE_QR_SESSION_DEVICE_ID_NOT_VALID) {
                  this.router.navigate(['/e/please-scan-new-qr'])
                }
                break
  
              case HttpError?.Forbidden:
                if (error?.error?.error === ErrorMessage.CUSTOMER_LOGIN_REQUIRED) {
                  this._viewService.logout('login', 'YOUR_SESSION_EXPIRED')
                  this._cartUtilitiesService.clearCarts(this.storeInfo?.qr || '')
                }
                break
  
              case HttpError?.InternalServerError:
                break
  
              case HttpError?.Unauthorized:
                console.error(error?.error?.error || 'Error unauthorized')
                break
  
              case HttpError?.ServerDown:
                break
              default:
                this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
                return true
            }
            return throwError(() => new Error(error?.error?.error))
          }
        }
      )
    )
  }
}
