import { IPhoneNumber } from './signup.interface';

export interface IAuthBySessionId {
  merchantId: number
  memberId: number
  member: Member
}

export interface Member {
  id: number
  publicId: string
  merchantId: number
  name: string
  email: string
  address: any
  phoneNumber: IPhoneNumber
  birthday: string
  joinDate: string
}
