import { Injectable } from '@angular/core';
import { ViewService } from '@data-access/view/view.service';
import { CartService } from '@home/data-access/cart.service';
import { CartList } from '@models/cart.model';
import { Store } from '@ngrx/store';
import { countOfCartUpdate } from '@state-management/count-of-cart/count-of-cart.actions';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartUtilitiesService {

  constructor(
    private _cartService: CartService,
    private _viewService: ViewService,
    private _store: Store
  ) { }

  getAllQtyOfItem(itemsOfCart:CartList[], productId?: number): number {
    let quantity = 0
    itemsOfCart?.map(function (element: CartList) {
      if (element?.productId == productId) {
        quantity += element?.quantity as number
      }
    })
    return quantity
  }

  isStockEmpty(stock: number, quantity: number, stockTracked: boolean): boolean {
    const productStock = stock || 0
    const isProductOutOfStock = productStock <= 0
    const isProductQtyExceedsStock = quantity > productStock
    const isStockTracked = stockTracked

    return ((isProductOutOfStock || isProductQtyExceedsStock) && isStockTracked) || false
  }

  isMaxStockCart(stock: number, quantity: number, stockTracked: boolean): boolean {
    const productStock = stock || 0
    const isProductOutOfStock = productStock <= 0
    const isProductQtyExceedsStock = quantity >= productStock
    const isStockTracked = stockTracked

    return ((isProductOutOfStock || isProductQtyExceedsStock) && isStockTracked) || false
  }

  clearCarts(qr: string = '', storeSlug: string = '') {
    let qrTemp: string = qr
    let storeSlugTemp: string = storeSlug
    let storeInfo = JSON.parse(this._viewService.get('storeInfo') ?? '{}');
    
    if (Object.keys(storeInfo).length) {
      qrTemp = storeInfo.qr
      storeSlugTemp = storeInfo.storeSlug
    }

    let payload = {
      deviceId: this._viewService.getDeviceId(qrTemp),
      customerDeviceId: this._viewService.getCustomerDeviceId(qrTemp),
      serviceType: this._viewService.getServiceType(qrTemp)
    }
    this._cartService.checkCart(storeSlugTemp, payload).subscribe(() => {
      this._store.dispatch(countOfCartUpdate({ count: 0 }))
    })
  }

  async updateCarts(qr: string = '', storeSlug: string = ''): Promise<void> {
    let qrTemp: string = qr
    let storeSlugTemp: string = storeSlug
    let storeInfo = JSON.parse(this._viewService.get('storeInfo') ?? '{}')

    if (Object.keys(storeInfo).length) {
        qrTemp = storeInfo.qr
        storeSlugTemp = storeInfo.storeSlug
    }

    try {
        await this._cartService.updateCartsBulk(qrTemp, storeSlugTemp).toPromise()
        
        this._store.dispatch(countOfCartUpdate({ count: 0 }))
    } catch (error) {
        console.error('Error updating carts:', error)
        of(null)
    }
  }
}
