import { LoadingSpinnerList } from "@utils/const";

export const environment = {
  production: false,
  uat: false,
  staging: true,
  local: false,
  preprod: false,
  showLog: true,
  recordLog: true,
  domain: 'stg.idefood.io',
  domainQueue: 'stg-queue.idefood.io',
  STRIPE_PK_KEY:
    'pk_test_51MYMOzDR6Y5g67tLcPRb2ND8yO0ClJBDdqUwBghCzL0ZwHRGt8KgNoQWS1VFOGg26EnEUVIgO4VcZ55wOx0mFvDF00GMG3Ib53',
  MAP_KEY: 'AIzaSyDXYIelvc9taXv5KTXwtUMQTqE7E21DvxY',
  NO_DISCOUNT_SERVICE_CHARGE_MERCHANT_ID: '1328',
  TAKEOUT_ABLE_PAY_AT_COUNTER_AND_ONLINE_PAYMENT: {
    mrEggFriedRice: '1375',
    mutiaraSeafood: '1376',
    mariMampir: '1216',
    aaysha: '1405',
  },
  TAKEOUT_ABLE_PAY_AT_COUNTER_ONLY: {
    saladier: '1428'
  },
  REMOVE_PAY_AT_COUNTER_DINE_IN: {
    SALADIER: '1428'
  },
  STORE_ID_WITH_CATALOG_SERVICE: [1470],
  STORE_QR_WITH_CATALOG_SERVICE: ['6ce899f4b3ac70d005b9f1a3e7f1ed8d'],
  DELIVERY_MINIMUM_ORDER_PRICE: 30,
  TIPPING_ADJUST_MERCHANT_ID: [1237],
  QUEUE_NUMBER_INFORMATION: [1485],
  LOADING_SPINNER_CUSTOM: {
    1113: LoadingSpinnerList.Ichiho
  },
  FONT_SIZE_CATEGORY: [1171],
  FREE_TO_OPTION_WORDING: [1485],
  SUB_TOTAL_WITHOUT_DISCOUNT: 1438
}
