import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ButtonComponent } from '@ui/button/button.component'
import { StoreInfo } from '../../models/home.model'
import { IlStoreNotOpenYetComponent } from '../../ui-svg/il-store-closed/il-store-closed.component'
import { Router } from '@angular/router'
import { ViewService } from '@data-access/view/view.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-delivery-not-available',
  templateUrl: './delivery-not-available.component.html',
  styleUrls: ['./delivery-not-available.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IlStoreNotOpenYetComponent,
    TranslateModule
  ]
})
export class DeliveryNotAvailableComponent {
  @Input() isShowModal: boolean = false
  @Input() storeInfo?: StoreInfo
  @Input() timeStart: string = ''
  @Input() timeEnd: string = ''
  @Input() availableDays: string[] = []

  constructor(
    private router: Router,
    public _viewService: ViewService,
    private _translateService: TranslateService
  ) { }

  timeFormatter(time: string): number {
    const timeArray = time.split(':') as string[]
    const hour = Number(timeArray[0]) as number
    const minute = Number(timeArray[1]) as number

    return new Date().setHours(hour, minute, 0, 0)
  }

  navigateToOrderService(): void {
    this._viewService.setServiceType('', this.storeInfo?.qr)
    this.router.navigate(['/order-service'], {
      queryParams: {
        qr: this.storeInfo?.qr
      }
    })
    this.isShowModal = false
  }

  get translatedAvailableDays(): string {
    return this.availableDays.map(day => this._translateService.instant(day)).join(', ')
  }

  get showAvailableDays(): boolean {
    return !!this.timeStart && !!this.timeEnd
  }

  get showAvailableTime(): boolean {
    return !this.timeStart && !this.timeEnd && !!this.availableDays.length
  }

  get showNotAvailable(): boolean {
    return !this.timeStart && !this.timeEnd && !this.availableDays.length
  }
}
