import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ViewService } from '@data-access/view/view.service';
import { StoreInfo } from '@models/home.model';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { map, Observable } from 'rxjs';
import { StoreInfoResolver } from '../resolver/store.resolver';
import { CartUtilitiesService } from '@data-access/utilities/cart-utilities.service';
import { CookieStorageKey } from '@utils/const';

@Injectable()
export class AuthVerifyGuard implements CanActivate {
  storeInfo: StoreInfo = new Object() as StoreInfo

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) public platformId: string,
    private _viewService: ViewService,
    private _storeInfoResolver: StoreInfoResolver,
    private _cartUtilitiesService: CartUtilitiesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._storeInfoResolver.resolve(route, state).pipe(
      map((response: StoreInfo) => {
        this.storeInfo = response

        let authToken = this._cookieService.get('authToken');
        let isLoggedIn: boolean = false
  
        /**
         * If loyalty === true,
         * Localstorage have a member (user) in storage = loggedIn true
         * authToken in storage, then remove authToken
         */
        
        if (this.storeInfo.isUseLoyalty) {
          if (this._cookieService.check(CookieStorageKey.member)) {
            isLoggedIn = true
          } else if (authToken) {
            this._cookieService.delete('authToken');
          }
        } else {
          /**
         * If loyalty === false,
         * authToken in storage, loggedIn = true
         * Localstorage have a member (user) in storage = and then remove
         * 
         */
  
          if (authToken) {
            isLoggedIn = true
          } else if (this._cookieService.check(CookieStorageKey.member)) {
            this._viewService.logout()
            this._cartUtilitiesService.updateCarts(this.storeInfo.qr, this.storeInfo.storeSlug)
          }
        }
  
        const key = route.queryParams['qr']
        let serviceType = '';
        if (isPlatformBrowser(this.platformId)) {
          let serviceTypeLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
          serviceType = serviceTypeLocalStorage['serviceType'] ?? '';
        }
  
        if (!isLoggedIn && (serviceType === 'DINE_IN' || serviceType === 'TAKEOUT')) {
          return true
        } else if (!isLoggedIn && serviceType === 'DELIVERY') {
          if (route.queryParams['qr']) {
            this.router.navigate(['/login'], {
              queryParams: route.queryParams,
            });
          }
          
          return false
        } else {
          return true
        }
      })
    )
  }
}
