<div class="app-wrapper">
  <app-store-holiday
    [isShowModal]="isShowStoreHoliday"
    [storeInfo]="storeInfo"></app-store-holiday>

  <app-store-closed [isShowModal]="isShowStoreClosed" [storeInfo]="storeInfo">
  </app-store-closed>

  <app-delivery-not-available
    *ngIf="isDelivery"
    [isShowModal]="isDeliveryNotAvailable"
    [storeInfo]="storeInfo"
    [timeStart]="timeStart"
    [timeEnd]="timeEnd"
    [availableDays]="availableDeliveryDays"
    >
  </app-delivery-not-available>
  
  <app-language-modal
    [isShow]="isShowLanguage"
    [storeInfo]="storeInfo"></app-language-modal>

  <app-contact-waiters
    [isShowModal]="isShowModalContactCashier"
    ill="QR_NOT_HAVE_SERVICE"
    [title]="'SORRY'"
    [desc]="'ORDER_SERVICE_TYPE_NOT_AVAILABLE'"></app-contact-waiters>

  <app-contact-waiters
    [isShowModal]="isShowModalMerged"
    ill="QR_NOT_HAVE_SERVICE"
    title="SORRY"
    desc="MERGED_DESC"
    [btnActionFlex]="true"
    btnActionText="GO_TO_ORDER"
    (confirm)="
      isShowModalMerged = $event; goToInvoice()
    "
    (close)="isShowModalMerged = $event"></app-contact-waiters>

  <router-outlet></router-outlet>
  <ngx-spinner
    [bdColor]="getSpinnerAnimation.overlay || ''"
    size="medium"
    color="#fff"
    [fullScreen]="true"
    type="ball-atom">
    <img
      [attr.width]="getSpinnerAnimation.width"
      [src]="getSpinnerAnimation.url"
      [alt]="getSpinnerAnimation.name" />

    <p
      style="
        color: white;
        font-family: 'var(--font-family)';
        font-weight: 400;
        text-align: center;
      ">
      {{ 'LOADING' | translate }}...
    </p>
  </ngx-spinner>
  <app-toast></app-toast>
</div>
