import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { error404Component } from './error/feature/404/404.component'
import { AuthNotVerifyGuard } from './shared/guard/auth-not-verify.guard'
import { AuthVerifyGuard } from './shared/guard/auth-verify.guard'
import { ChooseOrderServiceGuard } from './shared/guard/choose-order-service.guard'
import { StoreInfoResolver } from './shared/resolver/store.resolver'

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'e',
    loadChildren: () =>
      import('./error/feature/error.module').then(m => m.ErrorModule)
  },
  {
    path: '',
    canActivate: [AuthNotVerifyGuard],
    loadChildren: () =>
      import('./auth/feature/auth.module').then(m => m.AuthModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: '',
    loadChildren: () =>
      import('./home/feature/home.module').then(m => m.HomeModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    },
    canActivate: [ChooseOrderServiceGuard],
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order-history/feature/order-history.module').then(
        m => m.OrderHistoryModule
      ),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'favorite',
    canActivate: [AuthVerifyGuard],
    loadChildren: () =>
      import('./favorite/feature/favorite.module').then(m => m.WishListModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'my-coupon',
    loadChildren: () =>
      import('./my-coupon/feature/my-coupon.module').then(m => m.MyCouponModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'my-voucher',
    loadChildren: () =>
      import('./my-vouchers/feature/my-voucher.module').then(m => m.MyVouchersModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/feature/profile.module').then(m => m.ProfileModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'order-service',
    loadChildren: () =>
      import('./order-service/feature/order-service.module').then(
        m => m.OrderServiceModule
      ),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/feature/checkout.module').then(m => m.CheckoutModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: 'claim-points',
    loadChildren: () => import('./claim-points/feature/claim-points.module').then(m => m.ClaimPointModule),
    resolve: {
      storeInfoResolver: StoreInfoResolver
    }
  },
  {
    path: ':storeSlug',
    resolve: {
      storeInfoResolver: StoreInfoResolver
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: '',
        canActivate: [AuthNotVerifyGuard],
        loadChildren: () =>
          import('./auth/feature/auth.module').then(m => m.AuthModule)
      },
      {
        path: '',
        canActivate: [ChooseOrderServiceGuard],
        loadChildren: () =>
          import('./home/feature/home.module').then(m => m.HomeModule)
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./order-history/feature/order-history.module').then(
            m => m.OrderHistoryModule
          )
      },
      {
        path: 'favorite',
        canActivate: [AuthVerifyGuard],
        loadChildren: () =>
          import('./favorite/feature/favorite.module').then(
            m => m.WishListModule
          )
      },
      {
        path: 'my-coupon',
        loadChildren: () =>
          import('./my-coupon/feature/my-coupon.module').then(
            m => m.MyCouponModule
          )
      },
      {
        path: 'profile',
        canActivate: [AuthVerifyGuard],
        loadChildren: () =>
          import('./profile/feature/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'order-service',
        loadChildren: () =>
          import('./order-service/feature/order-service.module').then(
            m => m.OrderServiceModule
          )
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./checkout/feature/checkout.module').then(
            m => m.CheckoutModule
          )
      }
    ]
  },
  // Idepos Route
  {
    path: 'idepos',
    loadChildren: () =>
      import('./order-history/feature/order-history.module').then(
        m => m.OrderHistoryModule
      )
  },
  {
    path: '**',
    component: error404Component
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
