import { createReducer, on } from '@ngrx/store'
import { updateSyncAddressState } from './address-info.actions'

const isSyncAddressState: {
  isSyncAddress: boolean
} = {
  isSyncAddress: false
}

export const addressInfoReducer = createReducer(
  isSyncAddressState,
  on(updateSyncAddressState, (state, { isSyncAddress }) => ({ ...state, isSyncAddress })),
)
