import { ValidatorFn } from "@angular/forms"

export function makeId(length: number) {
  let result = ''
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export class HttpError {
  static BadRequest = 400
  static Unauthorized = 401
  static Forbidden = 403
  static NotFound = 404
  static TimeOut = 408
  static Conflict = 409
  static InternalServerError = 500
  static ServerDown = 503
  static UnprocessableEntityError = 422
}

export interface CountryListUtil {
  countryId: string
  currencyId: string
  langId: string
  currencyLabel: string
  currencySymbol: string
  name: string
  locale: string
}

export interface RoundingNear {
  code: string
  name: string
  divider: number
}

export interface PhoneCountryCode {
  name: string
  value: string
  country: string
}
export interface QueryParams {
  [key: string]: string | number;
}

export interface qrStorageId { serviceType: string, customerDeviceId: string, deviceId: string }

export interface jsonRules { [key: string]: [any, (ValidatorFn)[]] }
export interface jsonValidationMessage { [key: string]: Object }