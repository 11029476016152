import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { DomainService } from '@data-access/domain/domain.service';
import { ViewService } from '@data-access/view/view.service';
import { HomeServiceImpl } from '@home/data-access/home.service-impl';
import { ConfigSetting, CookieStorageKey, MicroSentryData, ServiceType } from '@utils/const';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataStorageService } from '../data-storage/data-storage.service';
import { LogService } from '../log/log.service';
import { QRInfo } from '@models/domain.model';
import { StoreInfo } from '@models/home.model';
import { AuthMemberService } from '@auth/data-access/auth-member.service';
import { AuthStateService } from '@auth/state/auth-state.service';
import { Store } from '@ngrx/store';
import { ResetCheckout } from '@state-management/checkout/checkout-info.actions';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ClaimPointsService } from 'src/app/claim-points/data-access/claim-points.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreInfoResolver implements Resolve<StoreInfo> {
  constructor(
    private _domainService: DomainService,
    private _homeService: HomeServiceImpl,
    private _dataStorage: DataStorageService,
    private _router: Router,
    private _viewService: ViewService,
    private _logService: LogService,
    private _spinner: NgxSpinnerService,
    private authMemberService: AuthMemberService,
    private authStateService: AuthStateService,
    private _store: Store,
    private _cookieService: SsrCookieService,
    private _claimPointService: ClaimPointsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<StoreInfo> {
    const storeSlug: string = route.params['storeSlug'];
    this._dataStorage.setData('storeSlug-param', storeSlug);
    this._dataStorage.setData('queryParams', route.queryParams);
    
    const merchantId: number = route.queryParams?.['merchantId']
    const storeId: number = route.queryParams?.['storeId']
    let codeClaimPoints: string = route.queryParams?.['code']
    const qr: string = route.queryParams?.['qr']

    // Access Claim Points but no have code
    if (route.routeConfig?.['path'] === 'claim-points' && !codeClaimPoints) {
      this._router.navigate(['/e/404'])
    }

    if (!qr && !merchantId && !storeId && !codeClaimPoints) {
      this._router.navigate(['/e/404'])
    } 

    if ((route.queryParams?.['qr'] && !route.queryParams?.['qr'].includes(ConfigSetting.FROM_CLAIM_POINTS)) && (!merchantId && !storeId)) {
      if (!this._dataStorage.getData('qrInfoResponse') || !this._dataStorage.getData('storeInfoResponse')) {
    
        // Get QR info
        return this._domainService.getQRInfo(route.queryParams?.['qr']).pipe(
          // Use switchMap to switch to the result of getStoreInfo with the obtained data
          switchMap((qrInfo) => {
            let qrInfoData: QRInfo = qrInfo.data;
            // Get store info using the dynamically obtained storeSlug
            return this._homeService.getStoreInfo(qrInfoData.storeSlug).pipe(
              tap((storeInfo) => {  
                let storeInfoData: StoreInfo = storeInfo.data;
                storeInfoData.serviceType = qrInfoData.serviceType
                if ((qrInfoData.serviceType.length === 1 &&
                    qrInfoData.serviceType.includes(ServiceType.DINE_IN) &&
                    environment.STORE_ID_WITH_CATALOG_SERVICE.includes(storeInfoData.storeId)) ||
                    environment.STORE_QR_WITH_CATALOG_SERVICE.includes(route.queryParams?.['qr'])){
                  qrInfoData.serviceType = [...qrInfoData.serviceType, 'CATALOG']
                  storeInfoData.serviceType = [...storeInfoData.serviceType, 'CATALOG']
                }
                this._dataStorage.setData('qrInfoResponse', qrInfoData);
                this._viewService.setQRInfo(qrInfoData)
                if (qrInfoData) {
                  Object.assign(storeInfoData, {
                    isAlreadyOrder: qrInfoData.isAlreadyOrder,
                    isOpenBill: qrInfoData.isOpenBill,
                    openBillId: qrInfoData.openBillId,
                    paxSizeSelected: qrInfoData.paxSizeSelected,
                    paxSizeMax: qrInfoData.paxSizeMax,
                    paxSizeChangeable: qrInfoData.paxSizeChangeable,
                    isDineInQR: qrInfoData.isDineInQR,
                    sessionDeviceId: qrInfoData.sessionDeviceId,
                    invoice: qrInfoData.invoice,
                    tableIds: qrInfoData.tableIds,
                    qrType: qrInfoData.qrType,
                    tableNumber: qrInfoData.tableNumber,
                    tableSplitNumber: qrInfoData.tableSplitNumber,
                    configService: qrInfoData.serviceType,
                    customerDeviceId: qrInfoData.customerDeviceId,
                    isReadOnly: qrInfoData.isReadOnly,
                    qr: route.queryParams?.['qr'],
                    openBillData: qrInfoData.openBillData,
                    isTableManagementActive: qrInfoData.isTableManagementActive,
                    storeSlug: qrInfoData.storeSlug,
                    isQueue: qrInfoData.isQueue,
                    isLoading: false
                  })
                }

                /**
                 * Clear Current User Active when merchantId no same with 
                 * current merchantId from storeInfo
                 */
                if (this._cookieService.check(CookieStorageKey.member)) {
                  const member = JSON.parse(this._cookieService.get(CookieStorageKey.member) ?? '{}') ?? {}
                  if (storeInfoData.merchantId !== member.merchantId) {
                    this.authMemberService.logout().subscribe({
                      next: (response) => {
                        this.authStateService.isLoggedInSubject.next(false)
                        this._viewService.clearSessionAuthStorage()
                        this._store.dispatch(ResetCheckout())
                        this._viewService.remove('dataVoucherCalculate')
                        sessionStorage.removeItem('checkoutField')
                        this._cookieService.delete('authToken', '/')
                        this._cookieService.delete(CookieStorageKey.member)
                      }
                    })
                  }
                }
                this._dataStorage.setData('storeInfoResponse', storeInfoData);
                this._viewService.setStoreInfo(storeInfoData)
              }),
              map((storeInfo) => storeInfo.data),
              catchError((error: HttpErrorResponse) => {
                // Handle the error here as needed
                console.error('Error fetching Store info:', error);
      
                if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
                  this._router.navigate(['/e/table-management-requires-activation'])
                } else {
                  this._router.navigate(['/e/404'])
                }
        
                this._spinner.hide()
        
                this._logService.error({ 
                  type: 'ERROR',
                  merchantId: 0,
                  storeId: 0,
                  qr: route.queryParams?.['qr'],
                  qrType: '',
                  dateTime: `${new Date()}`,
                  body: JSON.stringify({
                    infoUser: {
                      event: MicroSentryData.StoreInfo.message,
                      storeName: '',
                      className: 'StoreInfoResolver',
                      methodName: 'resolve',
                      deviceId: '',
                      customerDeviceId: '',
                      serviceType: '',
                      userAgent: navigator.userAgent,
                    },
                    data: error
                  })
                })
          
                if (error.error.status === 404) {
                  this._router.navigate(['/e/404']);
                }
          
                return throwError(() => error);
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
              this._router.navigate(['/e/table-management-requires-activation'])
            } else if (error.error.error === 'MERCHANT_TABLE_QR_NOT_FOUND') {
              this._router.navigate(['/e/404'])
            } else if (error.error.error === 'MERCHANT_TABLE_QR_EXPIRED') {
              this._router.navigate(['/e/qr-expired'])
            } else {
              this._router.navigate(['/e/404']);
            }

            this._logService.error({ 
              type: 'ERROR',
              merchantId: 0,
              storeId: 0,
              qr: route.queryParams?.['qr'],
              qrType: '',
              dateTime: `${new Date()}`,
              body: JSON.stringify({
                infoUser: {
                  event: MicroSentryData.StoreInfo.message,
                  storeName: '',
                  className: 'StoreInfoResolver',
                  methodName: 'resolve',
                  deviceId: '',
                  customerDeviceId: '',
                  serviceType: '',
                  userAgent: navigator.userAgent,
                },
                data: error
              })
            })
      
            // Optionally rethrow the error to propagate it further
            return throwError(() => error);
          })
        );
      } else {
        const qrInfoResponse = this._dataStorage.getData('qrInfoResponse')
        return of({...this._dataStorage.getData('storeInfoResponse'), ...qrInfoResponse})
      }
    } else if (!route.queryParams?.['qr'] && merchantId && storeId)  {
      if (!this._dataStorage.getData('storeInfoResponse')) {

        // Get store info using the dynamically obtained storeSlug
        return this._homeService.getStoreInfoById(storeId, merchantId).pipe(
          tap((storeInfo) => {  
            let storeInfoData = storeInfo.data;
            
            this._dataStorage.setData('storeInfoResponse', storeInfoData);
            this._viewService.setStoreInfo(storeInfoData)
          }),
          map((storeInfo) => storeInfo.data),
          catchError((error) => {
            // Handle the error here as needed
            console.error('Error fetching Store info:', error);
  
            if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
              this._router.navigate(['/e/table-management-requires-activation'])
            } else {
              this._router.navigate(['/e/404'])
            }
    
            this._spinner.hide()
    
            this._logService.error({ 
              type: 'ERROR',
              merchantId: 0,
              storeId: 0,
              qr: 'reset-password-from-kiosk',
              qrType: '',
              dateTime: `${new Date()}`,
              body: JSON.stringify({
                infoUser: {
                  event: MicroSentryData.StoreInfo.message,
                  storeName: '',
                  className: 'StoreInfoResolver',
                  methodName: 'resolve',
                  deviceId: 'reset-password-from-kiosk',
                  customerDeviceId: 'reset-password-from-kiosk',
                  serviceType: 'reset-password-from-kiosk',
                  userAgent: navigator.userAgent,
                },
                data: error
              })
            })
      
            if (error.error.status === 404) {
              this._router.navigate(['/e/404']);
            }
      
            return throwError(() => error);
          })
        );
      } else {
        return of({...this._dataStorage.getData('storeInfoResponse')})
      }
    } else if (codeClaimPoints && (!merchantId && !storeId && (!qr || qr.includes(ConfigSetting.FROM_CLAIM_POINTS))))  {
      if (!this._dataStorage.getData('storeInfoResponse') || !this._dataStorage.getData('unclaimedPointsResponse')) {

        if (qr && qr.includes(ConfigSetting.FROM_CLAIM_POINTS)) {
          codeClaimPoints = qr.split('-').slice(3, 8).join('-')
        }

        return this._claimPointService.unclaimedPoints(codeClaimPoints).pipe(
          switchMap((unclaimedPointsResponse) => {  
            let unclaimedPointsData = unclaimedPointsResponse.data;
            
            this._dataStorage.setData('unclaimedPointsResponse', unclaimedPointsData);

            // Get store info using the dynamically obtained storeSlug
            return this._homeService.getStoreInfoById(unclaimedPointsData.storeId, unclaimedPointsData.merchantId).pipe(
              tap((storeInfo) => {  
                let storeInfoData = storeInfo.data;

                Object.assign(storeInfoData, {
                  qr: `from-claim-points-${route.queryParams['code']}`,
                  code: codeClaimPoints,
                  codeEmailVerified: route.queryParams['code'],
                  isLoading: false,
                  unclaimedPointsData: unclaimedPointsData
                })

                this._dataStorage.setData('storeInfoResponse', storeInfoData);
                this._viewService.setStoreInfo(storeInfoData)
              }),
              map((storeInfo) => storeInfo.data),
              catchError((error) => {
                // Handle the error here as needed
                console.error('Error fetching Store info:', error);
      
                if (error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE') {
                  this._router.navigate(['/e/table-management-requires-activation'])
                } else {
                  this._router.navigate(['/e/404'])
                }
        
                this._spinner.hide()
        
                this._logService.error({ 
                  type: 'ERROR',
                  merchantId: 0,
                  storeId: 0,
                  qr: `from-claim-points-${route.queryParams['code']}`,
                  qrType: '',
                  dateTime: `${new Date()}`,
                  body: JSON.stringify({
                    infoUser: {
                      event: MicroSentryData.StoreInfo.message,
                      storeName: '',
                      className: 'StoreInfoResolver',
                      methodName: 'resolve',
                      deviceId: 'from-api-unclaimedPointsResponse',
                      customerDeviceId: 'from-api-unclaimedPointsResponse',
                      serviceType: 'from-api-unclaimedPointsResponse',
                      userAgent: navigator.userAgent,
                    },
                    data: error
                  })
                })
          
                if (error.error.status === 404) {
                  this._router.navigate(['/e/404']);
                }
          
                return throwError(() => error);
              })
            );
          }),
          catchError((error) => {
            // Handle the error here as needed
            console.error('Error fetching unclaimed points:', error);
            this._router.navigate(['/e/unclaimed-points-not-found'])
    
            this._spinner.hide()
    
            this._logService.error({ 
              type: 'ERROR',
              merchantId: 0,
              storeId: 0,
              qr: `from-claim-points-${route.queryParams['code']}`,
              qrType: '',
              dateTime: `${new Date()}`,
              body: JSON.stringify({
                infoUser: {
                  event: MicroSentryData.StoreInfo.message,
                  storeName: '',
                  className: 'StoreInfoResolver',
                  methodName: 'resolve',
                  deviceId: 'from-api-unclaimedPointsResponse',
                  customerDeviceId: 'from-api-unclaimedPointsResponse',
                  serviceType: 'from-api-unclaimedPointsResponse',
                  userAgent: navigator.userAgent,
                },
                data: error
              })
            })
      
      
            return throwError(() => error);
          })
        );
      } else {
        return of({...this._dataStorage.getData('storeInfoResponse'), ...this._dataStorage.getData('unclaimedPointsResponse')})
      }
    } else {
      return of()
    }
  
  }
}
