import { createReducer, on } from '@ngrx/store'
import { updateDeliveryState } from './delivery.actions'

const isDeliveryAvaillableState: {
  isDeliveryNotAvailable: boolean
} = {
  isDeliveryNotAvailable: false
}

export const DeliveryInfoReducer = createReducer(
  isDeliveryAvaillableState,
  on(updateDeliveryState, (state, { isDeliveryNotAvailable }) => ({ ...state, isDeliveryNotAvailable })),
)
