import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DataStorageService } from '@data-access/data-storage/data-storage.service'
import { SsrCookieService } from 'ngx-cookie-service-ssr'
import { Observable } from 'rxjs'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _ssrCookiesService: SsrCookieService, 
    private _dataStorage: DataStorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._ssrCookiesService.get('authToken');
    let headers: Headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json'
    };

    if (Object.keys(this._dataStorage.memberData).length) {
      const member = this._dataStorage.memberData;
      headers['x-member-id'] = `${member?.memberId}` || '';
    } else {
      headers['x-customer-token'] = token || '';
    }
  
    req = req.clone({ setHeaders: {...headers} });
  
    return next.handle(req);
  }
}

interface Headers {
  'Content-Type': string;
  Accept: string;
  'x-member-id'?: string;
  'x-customer-token'?: string;
}
