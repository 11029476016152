<ng-container *ngIf="isShowModal">
  <div class="modal-overlay"></div>
  <div class="modal">
    <div class="ill-delivery-info">
      <app-il-store-closed></app-il-store-closed>
    </div>

    <div *ngIf="showAvailableTime" class="content">
      <h2>{{ 'DELIVERY_SERVICE_AVAILABLE_AT' | translate }}</h2>
      <h2>{{ timeFormatter(timeStart) | date:'h:mm a' }} - {{ timeFormatter(timeEnd) | date:'h:mm a'  }}</h2>
    </div>
    <div *ngIf="showAvailableTime" class="content">
      <h2>{{ 'DELIVERY_SERVICE_AVAILABLE_ON' | translate }}</h2>
      <h2>{{ translatedAvailableDays  }}</h2>
    </div>
    <div *ngIf="showNotAvailable" class="content">
      <h2>{{ 'DELIVERY_SERVICE_NOT_AVAILABLE' | translate }}</h2>
    </div>

    <app-button
      btnClass="btn-primary"
      height="40px"
      padding="0"
      (click)="navigateToOrderService()">
      {{ 'BACK_TO_ORDER_SERVICE' | translate }}
    </app-button>
  </div>
</ng-container>
